import clsx from "clsx";
import React from "react";
import HTypography from "../HTypography";
import s from "./styles.module.scss";

interface TabPanelProps {
  label: string;
  children: React.ReactNode;
  className?: string;
}

const TabPanel: React.FC<TabPanelProps> = ({ label, children, className }) => {
  return <div className={className}>{children}</div>;
};

interface TabProps {
  active: number;
  children: React.ReactNode;
  onChange: (index: number) => void;
  direction?: "vertical" | "horizotal";
  className?: string;
}

const Tab: React.FC<TabProps> = ({
  active,
  children,
  onChange,
  direction = "vertical",
  className
}) => {
  return (
    <div className={clsx(direction === "vertical" ? s.vertical : s.horizotal)}>
      {/* Render the tab labels */}
      <div
        className={clsx(
          direction === "vertical" ? s.verticalLeft : s.horizotal,
          className
        )}
      >
        {React.Children.map(children, (child, index) => {
          const childElement = child as React.ReactElement<any>;
          const { label } = childElement.props;

          return (
            <button
              className={`relative z-20 mx-4 leading-5 transition duration-150 ease-in-out hover:text-primary ${
                index === active
                  ? "text-primary border-b-2 border-primary"
                  : "text-gray-500"
              }`}
              onClick={() => onChange(index)}
            >
              <HTypography variant="h6">{label}</HTypography>
            </button>
          );
        })}
      </div>
      <div
        className={clsx(
          direction === "vertical" ? s.verticalRight : s.horizotal
        )}
      >
        {/* Render the active tab panel */}
        {React.Children.map(children, (child, index) => {
          if (index !== active) {
            return null;
          }

          return (
            <div
              className={clsx(
                direction === "vertical" ? s.verticalButton : s.horizotalButton
              )}
            >
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { Tab, TabPanel };
