import React from "react";
import { IButtonFBProp } from "./index.type";
import icFB from "../../../assets/login/fb.png";
import styles from "./styles.module.scss";
import HButton from "@/components/ui/HButton";
import HImage from "@/components/ui/HImage";

const HButtonFB: React.FC<IButtonFBProp> = ({
  clientId,
  loginChallenge,
  rememberMe,
}) => {
  return (
    <HButton
      tag="a"
      href={`/auth/social/facebook?client_id=${clientId}&login_challenge=${loginChallenge}&remember_me=${rememberMe}`}
      variant="default"
      size="full"
      rounded="round-md"
    >
      <HImage
        alt="ICANID FB"
        src={icFB}
        width={24}
        height={24}
        layout="fixed"
      />
    </HButton>
  );
};

HButtonFB.defaultProps = {
  rememberMe: false,
};

export default HButtonFB;
