import { IHInputProps } from "./index.type";
import styles from "./styles.module.scss";
import { isIOS } from "react-device-detect";
import clsx from "clsx";

const HInput = (props: IHInputProps) => {
  const { className = "", ...attributes } = props;
  return (
    <div className="relative">
      <input
        className={clsx(
          styles.input,
          className,
          isIOS && "disabled:text-gray-800"
        )}
        //className={`${styles.input} ${className}`}
        {...attributes}
        onChange={attributes?.onChange}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
      <div className="absolute text-base font-medium right-5 top-1/3 text-primary">
        {attributes.btnPrefix}
      </div>
    </div>
  );
};

export default HInput;
