import { BaseAPI } from "./base";

class OauthAPI extends BaseAPI {
  constructor() {
    super({ resource: "/api/oauth" });
  }

  async getExchangeCode(code?: any) {
    return await this._request.post(this.getResource() + "/exchange", { code });
  }

  async getAccountByEmail(param: string) {
    return await this._request.get(this.getResource(`search?key=${param}`));
  }

  async forgotPassword(data: any) {
    return await this._request.post(
      this.getResource("otp/forgot-password/send"),
      data
    );
  }

  // async forgotPasswordVerify(data) {
  //   return await this._request.post(
  //     this.getResource("otp/forgot-password/verify"),
  //     data
  //   );
  // }

  async verifyActionForgotPassword(data: { key: string; code: string }) {
    return await this._request.post(
      this.getResource("otp/forgot-password/verify"),
      data
    );
  }

  async forgotPasswordUpdate(data: any) {
    return await this._request.put(
      this.getResource("otp/forgot-password/accept"),
      data
    );
  }

  async resetPassword(data: any) {
    return await this._request.post(this.getResource("otp/verify"), data);
  }

  async revokeToken(accessToken: string) {
    return await this._request.post(this.getResource("revoke"), {
      access_token: accessToken,
    });
  }

}

export default new OauthAPI();
