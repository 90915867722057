import AuthForm from "@/components/auth/AuthForm";
import HFooter from "@/components/common/HFooter";
import HNavbar from "@/components/common/HNavbar";
import HSelectLang from "@/components/common/HSelectLang";
import { NextPage, GetServerSideProps, InferGetServerSidePropsType } from "next";
import { getCookie, setCookie} from 'cookies-next';
import { useLanguage } from "@/context/language";
import { useEffect } from "react";


export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req, res, params } = context;

  const cookieLang = getCookie("locale", { req, res });

  // Có 2 version login_challenge
  // V1: https://test.dev.icanx.vn/login?login_challenge=12bcd3e66995429ca013089f7b223540
  // V2: Sử dụng trong cookie
  // -> Phải hỗ trợ cả 2 version
  console.log('\n\n[slug]');
  let cookieLoginChlg = getCookie("login-challenge", { req, res });
  console.log('cookieLoginChlg', cookieLoginChlg);

  let queryLoginChlg = context.query.login_challenge;
  console.log('queryLoginChlg', queryLoginChlg);

  if (!cookieLoginChlg && !queryLoginChlg) {
    // redirect init lại luồng login
    console.log('re-init login flow');
    return {
      props: {
        cookieLang: cookieLang ? cookieLang : params?.slug === "ic-connect" ? "en" : "vi"
      },
      // redirect: {
      //   permanent: false,
      //   destination: process.env.NEXT_PUBLIC_API_REDIRECT
      // }
    }
  }

  return {
    props: {
      // Ưu tiên sử dụng cookie, các trang cũ, bị lưu URL
      loginChallenge: cookieLoginChlg || queryLoginChlg,
      cookieLang: cookieLang ? cookieLang : params?.slug === "ic-connect" ? "en" : "vi"
    },
  };
}

const AuthPage: NextPage = ({ loginChallenge, cookieLang }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { setLocales } = useLanguage();

  useEffect(() => {
    setLocales(cookieLang)
  },[cookieLang, setLocales])

  return (
      <div className="flex flex-col justify-between">
        <div className="hidden mobile:block">
          <HNavbar />
        </div>
        <div className="relative">
          <div className="absolute w-fit top-6 right-6 mobile:hidden">
            <HSelectLang />
          </div>
          <AuthForm
            loginChallenge={loginChallenge}
          />
        </div>
        <div className="hidden mobile:block">
          <HFooter />
        </div>
      </div>
  );
};

export default AuthPage;
