import HErrorMessage from "@/components/common/HErrorMessage";
import HModalError from "@/components/common/HModalError";
import HButton from "@/components/ui/HButton";
import HInput from "@/components/ui/HInput";
import { HInputPassword } from "@/components/ui/HInputPassword";
import { useLanguage } from "@/context/language";
import auth from "@/services/module/auth";
import { IError } from "@/types/error";
import { onValidatePhone, passwordRegExp, phoneRegExp } from "@/utils/utils";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import * as Yup from "yup";
import styles from "./styles.module.scss";
import HSelect from "@/components/ui/HSelect";
import HModal from "@/components/ui/HModal";
import HTypography from "@/components/ui/HTypography";
import HImage from "@/components/ui/HImage";
import icSuccess from "../../../assets/ic-success.png";

interface IPhoneView {
  loginChallenge: String;
  onSubmit?: any;
  loading?: boolean;
  success?: boolean;
  state: "register" | "login";
  personSelect?: "child" | "person";
}
const PhoneView = (props: IPhoneView) => {
  const { messages } = useLanguage();
  const { onSubmit, loading, success, state, loginChallenge } = props;
  const router = useRouter();
  // const { login_challenge } = router.query;
  const [startTime, setStartTime] = useState<number | null>(null);
  const [error, setError] = useState<IError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [person, setPerson] = useState<"person" | "child">("person");
  const [visible, setVisible] = useState(false);

  const PhoneLoginSchema = Yup.object().shape({
    phone: Yup.string()
      .required(messages.warningEnterPhone)
      .min(9, messages.warningInvalidPhone)
      .max(10, messages.warningInvalidPhone)
      .matches(phoneRegExp, messages.warningInvalidPhone),

    password: Yup.string().required(messages.warningEnterPassword),
  });

  const PhoneRegisterSchema = Yup.object().shape({
    phone: Yup.string()
      .required(messages.warningEnterPhone)
      .min(9, messages.warningInvalidPhone)
      .max(10, messages.warningInvalidPhone)
      .matches(phoneRegExp, messages.warningInvalidPhone),
    password: Yup.string()
      .required(messages.warningEnterPassword)
      .min(8, messages.warningPasswordLong)
      .trim()
      .matches(passwordRegExp, messages.warningInvalidPassword),
    confirmPwd: Yup.string()
      .required(messages.warningEnterPasswordAgainCanNotbeBlank)
      .min(8, messages.warningPasswordLong)
      .oneOf([Yup.ref("password")], messages.warningReEnterPasswordDoesNotMath),
    code: Yup.string()
      .max(6, messages.warningCodeLong)
      .required(messages.warningCodeBlank)
      .matches(/^[0-9]*$/, messages.warningCodeFormat),
  });

  const onSendOTP = async ({ type, phone }: any) => {
    setIsLoading(true);
    const phoneIndex = phone.indexOf(0) === 0 ? phone : `0${phone}`;
    const payload = {
      type: type,
      challenge: loginChallenge,
      phone: phoneIndex,
    };
    try {
      const res = await auth.sendOTP(payload);
      if (res.data.success) {
        setStartTime(parseInt(res.data.expired));
        localStorage.setItem("key", res.data.key);
      }
    } catch (error: any) {
      setError(error?.response?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (startTime === null) {
      return;
    } else if (startTime < 0) {
      setStartTime(null);
    } else {
      setTimeout(() => {
        setStartTime((pre: any) => pre - 1);
      }, 1000);
    }
  }, [startTime]);

  const btnResend = (time: number, value: string) => {
    if (time === 0)
      return (
        <div
          className="cursor-pointer"
          onClick={() =>
            onSendOTP({
              type: "PHONE_CODE",
              phone: value.replace("+84 ", ""),
            })
          }
        ></div>
      );
    return <div>{time}</div>;
  };

  return (
    <div>
      <Formik
        initialValues={{
          password: "",
          confirmPwd: "",
          code: "",
          phone: "",
        }}
        onSubmit={onSubmit}
        validationSchema={
          state === "register" ? PhoneRegisterSchema : PhoneLoginSchema
        }
      >
        {({ dirty, values, isValid }) => {
          return (
            <Form>
              <Field id="phone" name="phone">
                {({ field, meta }: any) => (
                  <div className="relative my-3">
                    <span className="absolute z-10 left-3 top-1/4 text-[15px] font-normal">
                      +84
                    </span>
                    <HInput
                      type="text"
                      {...field}
                      placeholder={messages.enterphone}
                      className={clsx(
                        meta.error && "!border-red-400",
                        "!pl-11 text-[15px]"
                      )}
                      maxLength={10}
                      autoFocus={true}
                    />
                    <HErrorMessage
                      message={meta.touched && meta.error && meta.error}
                    />
                  </div>
                )}
              </Field>

              <Field id="password" name="password">
                {({ field, meta }: any) => (
                  <div className="my-3">
                    <HInputPassword
                      {...field}
                      placeholder={messages.enterpassword}
                      className={clsx(
                        meta.touched && meta.error && "!border-red-400"
                      )}
                      hint={state === "register"}
                      maxLength={50}
                      value={
                        values.password.length !== 0
                          ? values.password.replace(/ /g, "")
                          : values.password
                      }
                    />
                    <HErrorMessage
                      message={meta.touched && meta.error && meta.error}
                    />
                  </div>
                )}
              </Field>
              {state === "register" && (
                <Field id="confirmPwd" name="confirmPwd">
                  {({ field, meta }: any) => (
                    <div className="my-3">
                      <HInputPassword
                        {...field}
                        placeholder={messages.confirmPassword}
                        className={clsx(
                          meta.touched && meta.error && "!border-red-400"
                        )}
                        hint={true}
                      />
                      <HErrorMessage
                        message={meta.touched && meta.error && meta.error}
                      />
                    </div>
                  )}
                </Field>
              )}

              {state === "register" && (
                <Field id="code" name="code">
                  {({ field, meta }: any) => (
                    <div className="relative my-3">
                      <HInput
                        type="text"
                        {...field}
                        placeholder={messages.enterVerificationCode}
                        className={clsx(
                          meta.touched && meta.error && "!border-red-400"
                        )}
                        maxLength={6}
                        //disabled={!dirty}
                        btnPrefix={
                          startTime ? (
                            btnResend(startTime, values.phone)
                          ) : isLoading ? (
                            <span className={styles.loadingIcon}>
                              <AiOutlineLoading3Quarters />
                            </span>
                          ) : (
                            <HButton
                              tag="div"
                              size="xs"
                              className="cursor-pointer"
                              variant="primary"
                              outline
                              loading={isLoading}
                              disabled={
                                !values.phone ||
                                !values.password ||
                                !values.confirmPwd ||
                                isLoading
                              }
                              onClick={() =>
                                onSendOTP({
                                  type: "PHONE_CODE",
                                  phone: values.phone,
                                })
                              }
                            >
                              {messages.sendOtp}
                            </HButton>
                          )
                        }
                      />
                      <HErrorMessage
                        message={meta.touched && meta.error && meta.error}
                      />
                    </div>
                  )}
                </Field>
              )}

              {props.state === "register" && (
                <HSelect
                  value={
                    person === "person"
                      ? `${messages.personalaccount}`
                      : `${messages.childrenaccount}`
                  }
                  rawValue={true}
                  className="!border-0 !justify-center !shadow-none text-primary font-medium text-[16px]"
                  options={[
                    { label: `${messages.personalaccount}`, value: "person" },
                    { label: `${messages.childrenaccount}`, value: "child" },
                  ]}
                  onSelected={(e) => {
                    setPerson(e.value);
                    if (e.value === "child") {
                      setVisible(true);
                    }
                  }}
                />
              )}

              <HButton
                type="submit"
                size="full"
                variant="primary"
                rounded="round-full"
                className="rounded-[114px] my-2 uppercase"
                loading={props.loading}
                disabled={!dirty || !isValid || loading || success}
              >
                {state === "register" ? messages.register : messages.login}
              </HButton>
            </Form>
          );
        }}
      </Formik>

      {error && (
        <HModalError
          message={error.reason || error.message}
          onClose={() => {
            if (error.redirect_to) {
              router.push(error.redirect_to);
            } else {
              setError(null);
            }
          }}
        />
      )}

      {person === "child" && (
        <HModal visible={visible} showCloseButton={false} bodyClasses={"m-4"}>
          <div className="text-primary">
            <h4 className="text-[32px]">{ messages.createachildaccount }</h4>
          </div>
          <div>
            <span className="text-sm">
              ({ messages.tocreatethisaccount })
            </span>
          </div>
          <br />
          <div className="flex items-start justify-start text-left">
            <HImage
              className={"w-[5%]"}
              src={icSuccess}
              unoptimized={true}
              width={20}
              height={20}
            />
            <div className="w-[95%] ml-2 text-sm">
              { messages.setupandaccount }
            </div>
          </div>
          <br />
          <div className="flex items-start justify-start text-left">
            <HImage
              className={"w-[5%]"}
              src={icSuccess}
              unoptimized={true}
              width={20}
              height={20}
            />
            <div className="w-[95%] ml-2 text-sm">
              { messages.decideontherightexp }
            </div>
          </div>
          <br />
          <div className="flex items-start justify-start text-left">
            <HImage
              className={"w-[5%]"}
              src={icSuccess}
              unoptimized={true}
              width={20}
              height={20}
            />
            <div className="w-[95%] ml-2 text-sm">
              { messages.buildappropriatedevicetime }
            </div>
          </div>
          <br />
          <div>
            <HButton
              rounded="round-full"
              ghost
              className="sm:mr-3 smMax:mb-3"
              onClick={() => setPerson("person")}
            >
              { messages.back }
            </HButton>
            <HButton
              rounded="round-full"
              variant="primary"
              onClick={() => {
                setVisible(false);
                router.push("/family");
              }}
            >
              { messages.continue }
            </HButton>
          </div>
        </HModal>
      )}
    </div>
  );
};

export default PhoneView;
