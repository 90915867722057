import { BaseAPI } from "./base";

class AuthAPI extends BaseAPI {
  constructor() {
    super({ resource: "/auth" });
  }

  async register(data: any) {
    return await this._request.post(this.getResource("local/register"), data);
  }

  async login(data: any) {
    return await this._request.post(this.getResource("local/login"), data);
  }

  async sendOTP(data: any) {
    return await this._request.post(this.getResource("local/send"), data);
  }

  getListUserChoose(login_challenge?: string | string[]) {
    return this._request.get(
      this.getResource(`local/choose?login_challenge=${login_challenge}`)
    );
  }

  async onLoginMuitiAccount(data: any) {
    return await this._request.post(this.getResource(`local/choose`), data);
  }

  async onLogoutMuitiAccount(data: any) {
    return await this._request.post(
      this.getResource(`local/choose/logout`),
      data
    );
  }

  async changePasswordAuth(data: any) {
    return await this._request.put(
      this.getResource(`consent/password`),
      data
    );
  }

  async changePasswordSocial(data: any) {
    return await this._request.post(
      this.getResource(`consent`),
      data
    );
  }

  async confirmIdentification(query: string) {
    return await this._request.get(`/api/identification/confirm?identification=${query}`);
  }

  async sendOTPForChild(data: any) {
    return await this._request.post(`/api/verify/send`, data);
  }

  async verifyOTPForChild(data: any) {
    return await this._request.post(`/api/verify/grant`, data);
  }

  async registerAccountForKid(data: { challenge: string, email: string, password: string, relation_key: string, remember_me: boolean, key: string }) {
    return await this._request.post(this.getResource(`local/register`), data);
  }
}

export default new AuthAPI();
