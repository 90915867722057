import React from "react";
import icGG from "../../../assets/login/gg.png";
import styles from "./styles.module.scss";
import { IButtonGGProp } from "./index.type";
import HButton from "@/components/ui/HButton";
import HImage from "@/components/ui/HImage";

const HButtonGG: React.FC<IButtonGGProp> = ({
  clientId,
  loginChallenge,
  rememberMe,
}) => {
  return (
    <HButton
      tag="a"
      href={`/auth/social/google?client_id=${clientId}&login_challenge=${loginChallenge}&remember_me=${rememberMe}`}
      variant="default"
      className="w-full"
      size="full"
      rounded="round-md"
    >
      <HImage
        alt="ICANID GG"
        src={icGG}
        width={24}
        height={24}
        layout="fixed"
      />
    </HButton>
  );
};

HButtonGG.defaultProps = {
  rememberMe: false,
};

export default HButtonGG;
