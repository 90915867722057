import HButton from "@/components/ui/HButton";
import HModal from "@/components/ui/HModal";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import HInput from "@/components/ui/HInput";
import clsx from "clsx";
import s from "./styles.module.scss";
import oauth from "@/services/module/oauth";
import { IoReloadCircle } from "react-icons/io5";
import HModalSuccess from "@/components/common/HModalSuccess";
import { useLanguage } from "@/context/language";
import HModalError from "@/components/common/HModalError";
import { useRouter } from "next/router";

const ForgotPassword = () => {
  const { messages } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [step, setStep] = useState<any>(1);
  const [error, setError] = useState<any>();
  const [account, setAccount] = useState<any>({});
  const [time, setTime] = useState<any>(null);
  const [key, setKey] = useState("");
  const [option, setOption] = useState<string>("");
  const [otp, setOtp] = useState("");

  const validationSchema = Yup.object().shape({
    key: Yup.string().required(messages.warningEnterEmailAndPhone),
  });

  const onFinish = async (values: any) => {
    const { key } = values;
    setLoading(true);
    try {
      setError(null);
      const search = encodeURIComponent(key.trim());
      const { data } = await oauth.getAccountByEmail(search);
      if (data) {
        setAccount(data);
        setStep(2);
      }
    } catch (e: any) {
      setError(e?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  const forgotPassword = async (option: "EMAIL_LINK" | "PHONE_CODE") => {
    setLoading(true);
    const payload = {
      type: option,
      key: account.key,
    };
    try {
      const { data } = await oauth.forgotPassword(payload);
      if (data) {
        setStep(3);
        setKey(data.key);
        setTime(data.expired);
        setOption(option);
      }
    } catch (e: any) {
      setError(e?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  const verifyPhoneCode = async () => {
    try {
      const { data } = await oauth.verifyActionForgotPassword({
        key: key,
        code: otp,
      });
      setStep(null);
      window.location.href = data.redirectTo;
    } catch (e: any) {
      setError(e?.response?.data);
      //setStep(3);
    } finally {
      //setOtp("");
    }
  };

  useEffect(() => {
    if (time === null) {
      return;
    } else if (time < 0) {
      setTime(null);
    } else {
      setTimeout(() => {
        setTime((pre: any) => pre - 1);
      }, 1000);
    }
  }, [time]);
  const onclose = () => {
    setIsModal(false)
    setKey('')
  };
  return (
    <>
      <div
        className="font-semibold cursor-pointer text-primary"
        onClick={() => {
          setIsModal(true);
          setStep(1);
        }}
      >
        {messages.forgotpassword} ?
      </div>

      <HModal visible={isModal} showCloseButton={false}>
        {step === 1 && (
          <div>
            <div className="text-3xl font-bold text-center text-primary mobile:text-2xl">
              {messages.forgotpassword}
            </div>
            <div className="my-4 text-dark mx-auto text-[16px] font-normal mobile:text-[12px]">
              {/* Vui lòng nhập email và chúng tôi sẽ gởi hướng */}
              {messages.textForgotPassword}
            </div>
            <Formik
              initialValues={{
                key: "",
              }}
              validationSchema={validationSchema}
              onSubmit={onFinish}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ resetForm, values, dirty }) => (
                <Form>
                  <Field name="key">
                    {({ field, meta }: any) => (
                      <div className="mt-2">
                        <HInput
                          type="text"
                          {...field}
                          placeholder={messages.pleaseEnterInputPhoneOrEmail}
                          //hoặc số điện thoại
                          className={clsx(
                            meta.touched && meta.error && "!border-red-400 "
                          )}
                        />
                        <div className={s.textError}>
                          {meta.touched && meta.error && meta.error}
                        </div>
                        <div className={clsx(s.textError, "mb-3")}>
                          {(error && error?.reason) || error?.message}
                        </div>
                      </div>
                    )}
                  </Field>
                  <div className="flex justify-center">
                    <HButton
                      ghost
                      tag={"span"}
                      onClick={() => {
                        onclose();
                        // resetForm();
                        // setStep(1);
                      }}
                      size="md"
                      variant="primary"
                      rounded="round-full"
                      className="uppercase "
                      disabled={loading}
                    >
                      {messages.cancel}
                    </HButton>
                    <HButton
                      type="submit"
                      size="md"
                      variant="primary"
                      rounded="round-full"
                      className="ml-2 uppercase"
                      loading={loading}
                      disabled={loading || !dirty}
                    >
                      {messages.send}
                    </HButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {step === 2 && (
          <div className="px-2 mobile:p-0">
            <div className="text-3xl font-bold text-center text-primary">
              {messages.infoaccount}
            </div>
            <div className="my-2 text-dark">
              {messages.pleaseCheckAccountAgain}
            </div>
            <div className="pt-8">
              {account.email && (
                <div className="flex items-center justify-between">
                  <div className="font-bold text-[16px]">
                    Email: {account.email}
                  </div>
                  <HButton
                    onClick={() => forgotPassword("EMAIL_LINK")}
                    variant="primary"
                    size={"sm"}
                    rounded="round-full"
                    loading={loading}
                    disabled={loading}
                  >
                    {messages.confirm}
                  </HButton>
                </div>
              )}

              {account.phone && (
                <div className="flex items-center justify-between mt-4 ">
                  <div className="font-bold text-[16px]">
                    {messages.phonenumber}: {account?.phone}
                  </div>
                  <HButton
                    onClick={() => forgotPassword("PHONE_CODE")}
                    variant="primary"
                    size={"sm"}
                    rounded="round-full"
                    loading={loading}
                    // disabled={loading || success === "PHONE_CODE"}
                  >
                    {messages.confirm}
                  </HButton>
                </div>
              )}
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="text-center glx:p-0">
            {option === "PHONE_CODE" && (
              <>
                <div className="text-primary font-bold text-[32px] mobile:text-[24px] flex-nowrap mx-auto text-center ">
                  {messages.enterConfirmationCode}
                </div>
                <div className="py-3 text-[16px] font-normal">
                  <div>{messages.pleaseEnterConfirmationCode}</div>
                  <div className="font-black">{account?.phone}</div>
                </div>
                <div className="flex items-center justify-center w-full py-3 text-center "></div>

                <HInput
                  type="text"
                  placeholder="Nhập mã xác minh"
                  value={otp}
                  onChange={(e: any) => setOtp(e.target.value)}
                  maxLength={6}
                  btnPrefix={
                    time === null ? (
                      <div
                        className="flex items-center justify-center font-semibold cursor-pointer text-primary"
                        onClick={() => {
                          forgotPassword("PHONE_CODE");
                        }}
                      >
                        <IoReloadCircle className="mr-1" />
                        {messages.resend}
                      </div>
                    ) : (
                      `${time}s`
                    )
                  }
                />
                <div className="flex items-center justify-center py-3">
                  <HButton
                    variant="primary"
                    ghost
                    size="md"
                    rounded="round-full"
                    onClick={() => setStep(2)}
                  >
                    {messages.back}
                  </HButton>
                  <HButton
                    variant="primary"
                    size="md"
                    rounded="round-full"
                    className="rounded-full !w-[144px] ml-5"
                    onClick={() => verifyPhoneCode()}
                    loading={loading}
                  >
                    {messages.send}
                  </HButton>
                </div>
              </>
            )}
            {option === "EMAIL_LINK" && (
              <HModalSuccess
                message={`${messages.textSendConfirmationCode} ${account?.email}`}
                title={messages.emailSendSuccessfully}
                onClose={() => {
                  setStep(null);
                  setKey("");
                  setTime(null);
                  setOption("");
                  setIsModal(false);
                }}
              />
            )}
          </div>
        )}
      </HModal>
      {error && (
        <HModalError
          message={error?.reason || error?.message}
          onClose={() => {
            setError(null);
          }}
        />
      )}
    </>
  );
};

export default ForgotPassword;
