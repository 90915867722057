import clsx from "clsx";

interface HErrorMessageProps {
  message: any
  className?: string
}

const HErrorMessage: React.FC<HErrorMessageProps> = ({ message, className }) => {
  return (
    <div className={clsx("text-red-600 text-[10px] text-left ml-5 h-2", className)}>{message}</div>
  );
};

export default HErrorMessage;
