import clsx from "clsx";
import React from "react";

import { ICardProp } from "./index.type";
import styles from "./styles.module.scss";

const HCard = (prop: React.PropsWithChildren<ICardProp>) => {
  return (
    <div
      className={clsx(
        styles.card,
        {
          [styles.noBody]: prop.noBody,
          [styles.hover]: prop.hover,
          [styles.hasShadow]: prop.shadow,
          [styles.borderCard]: prop.border,
        },
        //  @ts-ignore
        prop?.className
      )}
    >
      {prop.children}
    </div>
  );
};

export default HCard;
