import axios, { AxiosInstance } from "axios";
import { initRequest } from "../config/axios";

export class BaseAPI {
  protected _request: AxiosInstance;
  protected _resource: string;

  constructor({ resource }: any) {
    this._resource = resource;
    this._request = initRequest();
  }

  protected getResource(path = "") {
    return path ? `${this._resource}/${path}` : this._resource;
  }
}
