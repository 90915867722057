import { useLanguage } from "@/context/language";
import { specialLetterRegExp } from "@/utils/utils";
import clsx from "clsx";
import { useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { twMerge } from "tailwind-merge";
import HInput from "../HInput";
import { IHInputProps } from "../HInput/index.type";
import s from "./styles.module.scss";

export const HInputPassword: React.FC<IHInputProps> = (props) => {
  const { messages } = useLanguage();
  const [showPassword, setShowPassword] = useState(true);
  const [hints, setHints] = useState(false);
  const v: any = props?.value?.toString();

  return (
    <div className="relative select-none">
      <HInput
        {...props}
        className={twMerge("!pr-10", props.className)}
        type={showPassword ? "password" : "text"}
        onFocus={() => setHints(true)}
        onMouseOut={() => setHints(false)}
        maxLength={50}
        //  @ts-ignore
        value={
          props?.value
            ? props?.value.toString().replaceAll(" ", "")
            : props?.value
        }
      />
      <div
        className="absolute right-2 top-1/2 !text-dark transform -translate-y-1/2 cursor-pointer"
        onClick={() => setShowPassword((s) => !s)}
      >
        {showPassword ? (
          <BsFillEyeSlashFill size={24} />
        ) : (
          <BsFillEyeFill size={24} />
        )}
      </div>
      {props.hint
        ? hints && (
            <div className={clsx(s.hint)}>
              <div>{messages.passwordSuggest}: </div>
              <ul className="pl-5 list-disc">
                <li
                  className={clsx(
                    /[0-9]/.test(v) && "text-primary font-medium"
                  )}
                >
                  {messages.number}
                </li>
                <li
                  className={clsx(
                    /[A-Z]/.test(v) && "text-primary font-medium"
                  )}
                >
                  {messages.letterUp}
                </li>
                <li
                  className={clsx(
                    /[a-z]/.test(v) && "text-primary font-medium"
                  )}
                >
                  {messages.letterLow}
                </li>
                <li
                  className={clsx(
                    specialLetterRegExp.test(v) && "text-primary font-medium"
                  )}
                >
                  {messages.lettetSpecial}
                </li>
                <li
                  className={clsx(
                    /.{8,}/.test(v) && "text-primary font-medium"
                  )}
                >
                  {messages.atleastletter}
                </li>
              </ul>
              <div className={s.hints} />
            </div>
          )
        : null}
    </div>
  );
};
