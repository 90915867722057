import axios, { AxiosInstance } from "axios";
import { getLocalStorageValue, getCookieStorage } from "@/utils/utils";
import * as Sentry from "@sentry/nextjs";
import { ITrackingLog } from "@/types/error";


export const BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL_API || "https://im-id.icanx.vn";

let request: AxiosInstance;

export const initRequest = () => {
  const headers = {
    language: getCookieStorage("locale"),
  };

  if (request) {
    request.defaults.headers.common = {
      ...request.defaults.headers.common,
      ...headers,
    };
  } else {
    request = axios.create({
      baseURL: `${BASE_URL}`,
      timeout: 5000,
      headers,
    });

    // @ts-ignore
    const responseInterceptor = request.interceptors.response?.handlers[0];
    if (!responseInterceptor) {
      request.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          console.log('interceptors response error');
          const data: ITrackingLog = {
            request: {
              body: JSON.parse(error.request.__sentry_xhr_v2__.body),//
              headers: error.request.__sentry_xhr_v2__.request_headers,
              method: error.request.__sentry_xhr_v2__.method,//
              url: error.request.__sentry_xhr_v2__.url
            },
            response: {
              body: error.response.data,
              status: error.response.status
            }
          };
          try {
            if (data) {
              fetch("https://tracking.stag.icanx.vn/logs/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  service: "im-id",
                  log_type: "ERROR",
                  env: process.env.NEXT_PUBLIC_ENV || '',
                  data: data
                }),
              });
            }
            Sentry.captureMessage(`${data}`);
          } catch (trackingError) {
            console.log('trackingError', trackingError);
          }

          if (error.data) {
            const { status } = error.response;
            if (status === 401) {
              localStorage.removeItem("token");
              window.location.href = "/";
            } else if (status === 404) {
              window.location.href = `/error?error_code=${status}`;
            } else {
              return error;
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log("Error: No response received");
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error: ", error.message);
          }
          return Promise.reject(error);
        }
      );
    }
  }

  return request;
};
