import clsx from "clsx";
import React from "react";
import s from "./styles.module.scss";

interface TypographyProps {
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  children: React.ReactNode;
}

const HTypography: React.FC<TypographyProps> = ({ variant, children }) => {
  const typographyClasses = {
    [`${s[variant]}`]: !!variant,
  };
  return <div className={clsx(typographyClasses)}>{children}</div>;
};

export default HTypography;
