import HErrorMessage from "@/components/common/HErrorMessage";
import HModalError from "@/components/common/HModalError";
import HButton from "@/components/ui/HButton";
import HInput from "@/components/ui/HInput";
import { HInputPassword } from "@/components/ui/HInputPassword";
import { useLanguage } from "@/context/language";
import auth from "@/services/module/auth";
import { IError } from "@/types/error";
import { passwordRegExp, phoneVali } from "@/utils/utils";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import * as Yup from "yup";

interface IEmailView {
  onSubmit?: any;
  loading?: boolean;
  success?: boolean;
  state: "register" | "login";
  loginChallenge: string;
}

// regex contact phone
const phoneRegExp = /((09|03|07|08|05)+([0-9]{8})\b)/g;

const ICTRegister: React.FC<IEmailView> = (props) => {
  const { messages } = useLanguage();

  const { onSubmit, loading, success, state, loginChallenge } = props;
  const router = useRouter();
  const merchantId = router.query.slug;
  const [startTime, setStartTime] = useState<number | null>(null);
  const [error, setError] = useState<IError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const onRegister = (value: any) => {
  //   const { contact_phone }: any = { ...value };
  //   const phone =
  //     contact_phone[0] === "0" ? contact_phone : "0" + contact_phone;
  //   const payload = { ...value, contact_phone: phone };
  //   onSubmit(payload);
  // };
  const LoginEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(messages.warningInvalidEmail)
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        messages.warningInvalidEmail + "."
      )
      .trim(messages.warningInvalidEmail + ".")
      .required(messages.warningEmailCannotBlank),
    password: Yup.string().required(messages.warningEnterPassword),
  });
  const RegisterEmailSchema = Yup.object().shape({
    contact_phone: Yup.string()
      .required(messages.warningEnterPhone)
      .matches(phoneRegExp, messages.warningInvalidPhone),
    email: Yup.string()
      .email(messages.warningInvalidEmail)
      .trim(messages.warningInvalidEmail)
      .required(messages.warningEmailCannotBlank)
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        messages.warningInvalidEmail + "."
      ),
    password: Yup.string()
      .required(messages.warningEnterPassword)
      .min(8, messages.warningPasswordLong)
      .trim()
      .matches(passwordRegExp, messages.warningInvalidPassword),
    display_name: Yup.string()
      .required(messages.warningFullnamEmpty)
      .min(3, messages.warningFullnameInvalid)
      .trim()
      .lowercase()
      .matches(new RegExp(/^[\p{L}\s]+$/u), messages.warningFullnameInvalid),
    confirmPwd: Yup.string()
      .required(messages.warningEnterPasswordAgainCanNotbeBlank)
      .min(8, messages.warningPasswordLong)
      .oneOf([Yup.ref("password")], messages.warningReEnterPasswordDoesNotMath),
    code: Yup.string()
      .max(6, messages.warningCodeLong)
      .required(messages.warningCodeBlank)
      .matches(/^[0-9]*$/, messages.warningCodeFormat),
  });

  const onSendOTP = async ({ type, email }: any) => {
    setIsLoading(true);
    const payload = {
      type: type,
      challenge: loginChallenge,
      email: email,
    };
    try {
      const res = await auth.sendOTP(payload);
      if (res.data.success) {
        setStartTime(parseInt(res?.data?.expired));
        localStorage.setItem("key", res.data.key);
      }
    } catch (error: any) {
      setError(error?.response?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (startTime === null) {
      return;
    } else if (startTime < 0) {
      setStartTime(null);
    } else {
      setTimeout(() => {
        setStartTime((pre: any) => pre - 1);
      }, 1000);
    }
  }, [startTime]);

  const btnResend = (time: number, value: string) => {
    if (time === 0)
      return (
        <div
          className="cursor-pointer"
          onClick={() =>
            onSendOTP({
              type: "EMAIL_CODE",
              email: value,
            })
          }
        ></div>
      );
    return <div>{time}</div>;
  };
  return (
    <div>
      {/* {error && (
        <HModalError
          message={error.reason || error.message}
          onClose={() => setError(null)}
        />
      )} */}
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirmPwd: "",
          code: "",
          display_name: "",
          contact_phone: "",
        }}
        onSubmit={onSubmit}
        validationSchema={
          state === "register" ? RegisterEmailSchema : LoginEmailSchema
        }
      >
        {({ dirty, values, isValid }) => {
          return (
            <Form>
              <Field id="emails" name="email">
                {({ field, meta }: any) => (
                  <div className="relative my-3">
                    <HInput
                      type="text"
                      {...field}
                      placeholder={messages.enteremail}
                      className={clsx(meta.error && "!border-red-400")}
                      autoFocus={true}
                      maxLength={100}
                      value={
                        values.email.length !== 0
                          ? values.email.replaceAll(" ", "")
                          : values.email
                      }
                    />
                    <HErrorMessage
                      message={meta.touched && meta.error && meta.error}
                    />
                  </div>
                )}
              </Field>

              <Field id="password" name="password">
                {({ field, meta }: any) => (
                  <div className="my-3">
                    <HInputPassword
                      {...field}
                      placeholder={messages.enterpassword}
                      className={clsx(
                        meta.touched && meta.error && "!border-red-400"
                      )}
                      hint={state === "register"}
                      maxLength={50}
                      value={
                        values.password.length !== 0
                          ? values.password.replaceAll(" ", "")
                          : values.password
                      }
                    />
                    <HErrorMessage
                      message={meta.touched && meta.error && meta.error}
                    />
                  </div>
                )}
              </Field>
              {props.state === "register" && (
                <Field id="confirmPwd" name="confirmPwd">
                  {({ field, meta }: any) => (
                    <div className="my-3">
                      <HInputPassword
                        {...field}
                        placeholder={messages.confirmPassword}
                        className={clsx(
                          meta.touched && meta.error && "!border-red-400"
                        )}
                        hint={false}
                      />
                      <HErrorMessage
                        message={meta.touched && meta.error && meta.error}
                      />
                    </div>
                  )}
                </Field>
              )}

              {merchantId === "ict" && state === "register" && (
                <>
                  <Field id="display_name" name="display_name">
                    {({ field, meta }: any) => (
                      <div className="my-3">
                        <HInput
                          {...field}
                          placeholder={messages.enterfullname}
                          className={clsx(
                            meta.touched && meta.error && "!border-red-400"
                          )}
                          maxLength={50}
                          // value={value}
                        />
                        <HErrorMessage
                          message={meta.touched && meta.error && meta.error}
                        />
                      </div>
                    )}
                  </Field>

                  <Field id="contact_phone" name="contact_phone">
                    {({ field, meta }: any) => (
                      <div className="my-3">
                        {/* <span className="absolute z-10 left-3 top-1/4 text-[15px] font-normal">
                          +84
                        </span> */}
                        <HInput
                          type="text"
                          {...field}
                          placeholder={messages.enterphone}
                          className={clsx(
                            meta.touched && meta.error && "!border-red-400"
                          )}
                          maxLength={10}
                        />
                        <HErrorMessage
                          message={meta.touched && meta.error && meta.error}
                        />
                      </div>
                    )}
                  </Field>
                </>
              )}

              {props.state === "register" && (
                <Field id="code" name="code">
                  {({ field, meta }: any) => (
                    <div className="relative my-3">
                      <HInput
                        type="text"
                        {...field}
                        placeholder={messages.enterVerificationCodeEmail}
                        className={clsx(
                          meta.touched && meta.error && "!border-red-400"
                        )}
                        maxLength={6}
                        //disabled={!active}
                        value={values.code.replaceAll(" ", "")}
                        btnPrefix={
                          startTime ? (
                            btnResend(startTime, values.email)
                          ) : isLoading ? (
                            <span className="mr-2 animate-spin">
                              <AiOutlineLoading3Quarters />
                            </span>
                          ) : (
                            <HButton
                              tag="div"
                              size="xs"
                              className="cursor-pointer"
                              variant="primary"
                              outline
                              disabled={
                                !values.email ||
                                !values.password ||
                                !values.confirmPwd ||
                                isLoading
                              }
                              onClick={() =>
                                onSendOTP({
                                  type: "EMAIL_CODE",
                                  email: values.email,
                                })
                              }
                            >
                              {messages.sendOtp} *
                            </HButton>
                          )
                        }
                      />

                      <HErrorMessage
                        message={meta.touched && meta.error && meta.error}
                      />
                      <div className="text-left ml-5 italic text-[14px] mt-3">
                        <div>
                          {messages.noticeOTPEmail1}{" "}
                          <span className="text-base font-bold text-primary">
                            {messages.noticeOTPEmail2}
                          </span>{" "}
                          {messages.noticeOTPEmail3}
                        </div>
                      </div>
                    </div>
                  )}
                </Field>
              )}
              <HButton
                type="submit"
                size="full"
                variant="primary"
                rounded="round-full"
                className="rounded-[114px] my-2 uppercase"
                loading={props.loading}
                disabled={!dirty || !isValid || loading || success}
              >
                {props.state === "register"
                  ? messages.register
                  : messages.login}
              </HButton>
            </Form>
          );
        }}
      </Formik>

      {error && (
        <HModalError
          message={error.reason || error.message}
          onClose={() => {
            if (error.redirect_to) {
              router.push(error.redirect_to);
            } else {
              setError(null);
            }
          }}
        />
      )}
    </div>
  );
};

export default ICTRegister;
