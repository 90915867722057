import HErrorMessage from "@/components/common/HErrorMessage";
import HButton from "@/components/ui/HButton";
import HInput from "@/components/ui/HInput";
import { HInputPassword } from "@/components/ui/HInputPassword";
import { useLanguage } from "@/context/language";
import auth from "@/services/module/auth";
import { passwordRegExp, phoneRegExp } from "@/utils/utils";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import * as Yup from "yup";

interface IUsernameView {
  loginChallenge: String;
  onSubmit?: any;
  loading?: boolean;
  success?: boolean;
  state: "register" | "login";
}
const UsernameView = (props: IUsernameView) => {
  const { messages } = useLanguage();
  const { onSubmit, loading, success, state } = props;
  const router = useRouter();
  const { login_challenge } = router.query;
  const [startTime, setStartTime] = useState<number | null>(null);

  const UserLoginSchema = Yup.object().shape({
    password: Yup.string().required(messages.warningEnterPassword),
    username: Yup.string().required(messages.warningUsernameCannotBeBlank),
  });

  const UserRegisterSchema = Yup.object().shape({
    username: Yup.string()
      .required(messages.warningUsernameCannotBeBlank)
      .min(8, messages.warningUsernameLong),
    password: Yup.string()
      .required(messages.warningEnterPassword)
      .min(8, messages.warningPasswordLong)
      .trim()
      .matches(passwordRegExp, messages.warningInvalidPassword),
    confirmPwd: Yup.string()
      .required(messages.warningEnterPasswordAgainCanNotbeBlank)
      .min(8, messages.warningPasswordLong)
      .oneOf([Yup.ref("password")], messages.warningReEnterPasswordDoesNotMath),
    code: Yup.string()
      .max(6, messages.warningCodeLong)
      .required(messages.warningCodeBlank)
      .matches(/^[0-9]*$/, messages.warningCodeFormat),
  });

  const onSendOTP = async ({ type, phone }: any) => {
    const payload = {
      type: type,
      challenge: login_challenge,
      phone: phone,
    };
    try {
      const res = await auth.sendOTP(payload);
      console.log(res);
      if (res.data.success) {
        setStartTime(parseInt(res.data.expired));
        localStorage.setItem("key", res.data.key);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (startTime === null) {
      return;
    } else if (startTime < 0) {
      setStartTime(null);
    } else {
      setTimeout(() => {
        setStartTime((pre: any) => pre - 1);
      }, 1000);
    }
  }, [startTime]);

  const btnResend = (time: number, value: string) => {
    if (time === 0)
      return (
        <div
          className="cursor-pointer"
          onClick={() =>
            onSendOTP({
              type: "PHONE_CODE",
              phone: value,
            })
          }
        ></div>
      );
    return <div>{time}</div>;
  };

  return (
    <div>
      <Formik
        initialValues={{
          password: "",
          confirmPwd: "",
          code: "",
          username: "",
          phone: "",
        }}
        onSubmit={onSubmit}
        validationSchema={
          state === "register" ? UserRegisterSchema : UserLoginSchema
        }
      >
        {({ dirty, values, isValid }) => {
          return (
            <Form>
              <Field id="username" name="username">
                {({ field, meta }: any) => (
                  <div className="relative my-3">
                    <HInput
                      type="text"
                      {...field}
                      placeholder={messages.enteraccount}
                      className={clsx(meta.error && "!border-red-400")}
                      autoFocus={true}
                      maxLength={20}
                      value={
                        values.username.length !== 0
                          ? values.username.replaceAll(" ", "")
                          : values.username
                      }
                    />
                    <HErrorMessage
                      message={meta.touched && meta.error && meta.error}
                    />
                  </div>
                )}
              </Field>

              <Field id="password" name="password">
                {({ field, meta }: any) => (
                  <div className="my-3">
                    <HInputPassword
                      {...field}
                      placeholder={messages.enterpassword}
                      className={clsx(
                        meta.touched && meta.error && "!border-red-400"
                      )}
                      hint={state === "register"}
                      maxLength={20}
                      value={
                        values.password.length !== 0
                          ? values.password.replaceAll(" ", "")
                          : values.password
                      }
                    />
                    <HErrorMessage
                      message={meta.touched && meta.error && meta.error}
                    />
                  </div>
                )}
              </Field>
              {state === "register" && (
                <Field id="confirmPwd" name="confirmPwd">
                  {({ field, meta }: any) => (
                    <div className="my-3">
                      <HInputPassword
                        {...field}
                        placeholder={messages.confirmPassword}
                        className={clsx(
                          meta.touched && meta.error && "!border-red-400"
                        )}
                        hint={true}
                      />
                      <HErrorMessage
                        message={meta.touched && meta.error && meta.error}
                      />
                    </div>
                  )}
                </Field>
              )}

              {state === "register" && (
                <Field id="code" name="code">
                  {({ field, meta }: any) => (
                    <div className="relative my-3">
                      <HInput
                        type="text"
                        {...field}
                        placeholder={messages.enterVerificationCode}
                        className={clsx(
                          meta.touched && meta.error && "!border-red-400"
                        )}
                        maxLength={6}
                        disabled={!dirty}
                        value={
                          values.code.length !== 0
                            ? values.code.replaceAll(" ", "")
                            : values.code
                        }
                        btnPrefix={
                          startTime ? (
                            btnResend(startTime, values.phone)
                          ) : (
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                onSendOTP({
                                  type: "PHONE_CODE",
                                  phone: values.phone,
                                })
                              }
                            >
                              {messages.sendOtp}
                            </div>
                          )
                        }
                      />
                      <HErrorMessage
                        message={meta.touched && meta.error && meta.error}
                      />
                    </div>
                  )}
                </Field>
              )}

              <HButton
                type="submit"
                size="full"
                variant="primary"
                rounded="round-full"
                className="rounded-[114px] my-2 uppercase"
                loading={props.loading}
                disabled={!dirty || !isValid || loading || success}
              >
                {state === "register" ? messages.register : messages.login}
              </HButton>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UsernameView;
