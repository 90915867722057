import HImage from "@/components/ui/HImage";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import styles from "./styles.module.scss";

import HCard from "@/components/ui/HCard";
import HButtonGG from "@/components/common/HButtonGG";
import HButtonFB from "@/components/common/HButtonFB";
import HButtonZalo from "@/components/common/HButtonZalo";
import Link from "next/link";
import HModalError from "@/components/common/HModalError";

import logo from "../../../assets/logo/logo-white-ican.png";
import icUser from "../../../assets/login/ic-user.png";

import bck from "../../../assets/logo/bckLogin.png";
import bckICT from "../../../assets/logo/bckICT.png";

import { Tab, TabPanel } from "@/components/ui/tabs";
import EmailView from "../EmailView";
import PhoneView from "../PhoneView";
import UsernameView from "../UsernameView";
import auth from "@/services/module/auth";
import { useLanguage } from "@/context/language";
import ForgotPassword from "../ForgotPassword";
import ICTRegister from "@/components/merchants/ict/register";
import { addVersion } from "@/utils/utils";
import UsernameEmailView from "../UnameAndEmailView";

type ILoginParamsProp = {
  identification: string;
  username: string;
  phone: string;
  challenge: string;
  password: string;
  email: string;
};

type IAuthFormProps = {
  loginChallenge: string;
};

const AuthForm = ({ loginChallenge }: IAuthFormProps) => {
  const router = useRouter();
  const { slug } = router.query;
  const { messages } = useLanguage();
  console.log("loginChallenge", loginChallenge);
  console.log("slug", slug);

  const clientId = slug;

  const [state, setState] = useState<"login" | "register">("login");
  const [error, setError] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(0);
  // Unused
  const [rememberMe] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onChangeState = (text: "login" | "register") => {
    setState(text);
  };

  const onLogin = useCallback(
    async (values: ILoginParamsProp) => {
      const phoneIndex = values.phone
        ? values.phone.replace("+84 ", "").indexOf("0", 0) === 0
          ? values.phone.replace("+84 ", "")
          : `0${values.phone.replace("+84 ", "")}`
        : null;
      if (isLoading) return;
      setIsLoading(true);
      const payload = {
        identification: values.identification,
        username: values.username,
        phone: phoneIndex,
        challenge: loginChallenge,
        password: values.password,
        email: values.email,
        remember_me: true,
      };
      try {
        const {
          data: { redirect_to },
        } = await auth.login(payload);
        if (redirect_to !== undefined) {
          router.push(redirect_to);
        }
      } catch (e: any) {
        setSuccess(false);
        setError(e?.response?.data);
      }
      setIsLoading(false);
    },
    [isLoading, loginChallenge, router]
  );

  const onRegister = useCallback(
    async (values: any) => {
      if (values.code === "")
        return setError({ message: messages.warningEnterCode });
      if (isLoading) return;
      setIsLoading(true);
      const phoneIndex = values.phone
        ? values.phone.replace("+84 ", "").indexOf("0", 0) === 0
          ? values.phone.replace("+84 ", "")
          : `0${values.phone.replace("+84 ", "")}`
        : null;
      try {
        const payload = {
          ...values,
          phone: phoneIndex,
          challenge: loginChallenge,
          key: localStorage.getItem("key"),
          remember_me: rememberMe,
          password: values.password.replaceAll(" ", ""),
        };

        const res = await auth.register(payload);
        if (res.data.redirect_to) {
          router.push(res.data.redirect_to);
          setSuccess(true);
        }
      } catch (e: any) {
        setSuccess(false);
        setError(e?.response?.data);
      }
      setIsLoading(false);
    },
    [isLoading, loginChallenge, messages.warningEnterCode, rememberMe, router]
  );

  const renderTabs = useMemo(() => {
    let tabs = (
      <Tab
        active={activeTab}
        onChange={(e: number) => setActiveTab(e)}
        direction="horizotal"
      >
        <TabPanel label={messages.phonenumber}>
          <PhoneView
            loginChallenge={loginChallenge}
            state={state}
            loading={isLoading}
            onSubmit={state === "register" ? onRegister : onLogin}
          />
        </TabPanel>
        <TabPanel label="Email">
          <EmailView
            loginChallenge={loginChallenge}
            state={state}
            loading={isLoading}
            onSubmit={state === "register" ? onRegister : onLogin}
          />
        </TabPanel>
      </Tab>
    );

    if (router.query.slug === "ict" && state === "login") {
      return (tabs = (
        <Tab
          active={activeTab}
          onChange={(e: number) => setActiveTab(e)}
          direction="horizotal"
        >
          <TabPanel label={messages.account}>
            <UsernameView
              loginChallenge={loginChallenge}
              state={state}
              loading={isLoading}
              onSubmit={onLogin}
            />
          </TabPanel>
          <TabPanel label="Email">
            <EmailView
              loginChallenge={loginChallenge}
              state={state}
              loading={isLoading}
              onSubmit={onLogin}
            />
          </TabPanel>
        </Tab>
      ));
    }
    if (router.query.slug === "ict" && state === "register") {
      return (tabs = (
        <ICTRegister
          state={state}
          loginChallenge={loginChallenge}
          loading={isLoading}
          onSubmit={state === "register" ? onRegister : onLogin}
        />
      ));
    }

    if (router.query.slug === "ic-connect" && state === "login") {
      return (tabs = (
        <Tab
          active={activeTab}
          onChange={(e: number) => setActiveTab(e)}
          direction="horizotal"
          className="text-start"
        >
          <TabPanel className="text-start" label="Email">
            <EmailView
              loginChallenge={loginChallenge}
              state={state}
              loading={isLoading}
              onSubmit={onLogin}
            />
          </TabPanel>
        </Tab>
      ));
    }

    return tabs;
  }, [activeTab, isLoading, loginChallenge, messages.account, messages.phonenumber, onLogin, onRegister, router.query.slug, state]);

  return (
    <div className="grid grid-cols-2 mobile:grid-cols-1 mobile:h-full h-screen bg-white mobile:bg-[#E1F1FF]">
      <div className={styles.authLeft}>
        <div className="relative w-full h-full">
          {slug === "ict" && (
            <HImage
              //className="mx-auto"
              alt="BCK ICANID"
              src="https://s3.icankid.io/ican-id/bu/z4703390967568_3815d6f0d657bf525b2c7ed87b11bfd1.jpg"
              layout="fill"
              sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
              unoptimized={true}
              //fill
            />
          )}
          {slug !== "ict" && (
            <HImage
              //className="mx-auto"
              alt="BCK ICT"
              src={bck}
              layout="fill"
              sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
              unoptimized={true}
              //fill
            />
          )}
        </div>
        {slug !== "ict" && (
          <div className="absolute mx-auto text-center top-5 left-[16%]">
            <HImage
              className="mx-auto"
              alt="LOGO ICANID"
              src={logo}
              width={167}
              height={74}
              layout="intrinsic"
            />
          </div>
        )}
      </div>
      <div className={styles.authForm}>
        <HCard className={styles.authFormCard} noBody>
          <div className="w-[410px] mobile:w-full mx-auto py-1 glx:w-full glx:px-2 mobile:py-10">
            <div>
              <HImage
                alt="ICANID"
                src={icUser}
                width={80}
                height={80}
                //layout="fixed"
                className="mx-auto"
              />
            </div>
            <div className="text-primary font-bold text-[32px]">
              {(state === "login") ? messages.login : messages.register}
            </div>
            <div className="p-6">{renderTabs}</div>
            {(state === "login" && router.query.slug !== "ic-connect") && <ForgotPassword />}
            {(router.query.slug !== "ict" && router.query.slug !== "ic-connect") && (
              <>
                <div className="text-[#9F9F9F] font-normal text-sm my-3 select-none">
                  {messages.orContinueWith}
                </div>
                <div className={clsx(styles.flexCenter)}>
                  <div className="mx-3">
                    <HButtonGG
                      clientId={clientId}
                      state={state}
                      loginChallenge={loginChallenge}
                      rememberMe={rememberMe}
                    />
                  </div>
                  <div className="mx-3">
                    <HButtonFB
                      clientId={clientId}
                      state={state}
                      loginChallenge={loginChallenge}
                      rememberMe={rememberMe}
                    />
                  </div>
                  {/* <div className="mx-3">
                    <HButtonZalo
                      clientId={clientId}
                      state={state}
                      loginChallenge={loginChallenge}
                      rememberMe={rememberMe}
                    />
                  </div> */}
                </div>
              </>
            )}
            <div className="mt-4 mb-6 text-[#484848] text-xs font-normal select-none w-[80%] mx-auto">
              {messages.questionTerms}{" "}
              <strong className="text-primary hover:cursor-pointer">
                <Link
                  href="https://www.ican.vn/dieu-khoan-su-dung"
                  target="_blank"
                  legacyBehavior
                >
                  {messages.termsInformation}
                </Link>
              </strong>{" "}
              {messages.and}{" "}
              <strong className="text-primary hover:cursor-pointer">
                <Link
                  href="https://www.ican.vn/chinh-sach-bao-mat"
                  target="_blank"
                  legacyBehavior
                >
                  {messages.privacyPolicy}
                </Link>
              </strong>
            </div>
            {router.query.slug !== "ic-connect" && (
              <div className="text-[#484848] text-base font-normal mobile:py-3 hover:cursor-pointer ">
                {state === "login" ? (
                  <>
                    {messages.questionNotAccount}{" "}
                    <div>
                      <strong
                        className="hover:underline text-primary hover:decoration-1"
                        onClick={() => onChangeState("register")}
                      >
                        {messages.register}
                      </strong>
                    </div>
                  </>
                ) : (
                  <>
                    {messages.questionAlreadyAccount}{" "}
                    <div>
                      <strong
                        className="hover:underline text-primary hover:decoration-1"
                        onClick={() => onChangeState("login")}
                      >
                        {messages.login}
                      </strong>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </HCard>
      </div>

      {error && (
        <HModalError
          title={messages.Errormessage}
          message={error.reason || error.message}
          onClose={() => {
            setError(null);
            if (error.redirect_to) {
              return router.push(error.redirect_to);
            }
            if (error.message === "CHALLENGE_NOT_FOUND") {
              // window.location.href = '/';
              router.push("/");
            }
          }}
        />
      )}
    </div>
  );
};

export default AuthForm;
